@use "src/_bg.scss";

@use "src/_btn.scss";
@use "src/_theme.scss";

@use "src/_side.scss";
@use "src/_token.scss";
@use "src/_wallet.scss";
@use "src/_footer.scss";

.App {
  text-align: center;
  padding: 16px;

}

.world {
  z-index: 2;

}


body {
  @include theme.textFont;

}
strong, button, input {
  @include theme.altFont;
}


body {
  background: theme.$bg;
  color: theme.$fontColor;
  width: 100%;

  height: 100vh;

  margin: 0;

}





a, a:visited {
  @include theme.gradBP();
  text-decoration: none;
  &:hover {
    @include theme.gradPB();
  }
}







@include btn.Btn;

.navi {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 50%;

  background: rgba(theme.$bg,0.8);
  padding: 8px 16px;
  box-sizing: border-box;

  border: 2px solid rgba(0,0,0,0.4);
  border-top-left-radius: 100vw;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}


.deli-container {
  border-bottom: 1px solid theme.$pink;
  
  max-width: 800px;
  margin: 0 auto 32px;
  p {
    margin-top: 8px;
    margin-bottom: 16px;
    background: rgba(theme.$bg,0.8);
    padding: 4px 8px;
    box-sizing: border-box;
    border: 2px solid rgba(black,0.4);
    filter: drop-shadow(2px 2px 2px black);
    border-radius: 2px;
  }

}

.connected--false .world {
  display: none;
}


@include token.Token;

.mmbtn {
  position: fixed;
  top: 16px;
  right: 16px;
  margin: 0;
  font-size: 20px;
}

.focus {
  padding: 32px 64px 64px;
  text-align: left;
  background: rgba(theme.$bg,0.6);
  border: 1px solid rgba(white, 0.4);
  filter: drop-shadow(2px 2px 2px black);
  border-radius: 4px;
  max-width: 1080px;
  margin: 256px 32px  16px;
  a { font-size: 24px;}
  a, p, h2 {filter: drop-shadow(2px 2px 2px black);}
  h2 {
    @include theme.gradBP();
    font-size: 32px;
  }
}

h1, h2, h3 {
  @include theme.titleFont;

}

h2 {
  @include theme.gradGP();
  font-size: 64px;
  display: inline-block;
}


.alchemist {
  max-width: 480px;
  margin: 16px auto;
  border-left: 1px solid theme.$theme;
  border-bottom: 2px solid theme.$themeTetrad;
  border-right: 1px solid theme.$themeTetradAnti;
  padding: 16px;
  box-sizing: border-box;
}


.Lander__Focus {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 50vh;
  box-sizing: border-box;
  h1 {
    font-size: 128px;
    font-weight: 400;
  }
 p {
  margin-left: 16px;
  margin-right: 16px;
 }

}

.Lander__SubFocus {
  position: fixed;
  top: 50vh;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  vertical-align: middle;
  a {
    display: inline-block;
    width: 30%;
    margin: 8px;
    box-sizing: border-box;
    height: 40vh;
    border: 1px solid white;
    line-height: 40vh;
    font-size: 28px;
  }
}




.miniInfo {
  width: 128px;
  margin: 8px;
  border: 2px solid theme.$green;
  display: inline-block;
  padding: 16px;
  border-radius: 8px;
  vertical-align: middle;
  span {
    display: block;
  }
  span + span {
    font-size: 24px;
  }
}


h3 {
   @include theme.gradBP();
   font-size: 48px;
   margin: 0;
}



button:disabled,
button[disabled] {
  filter: grayscale(1);
  cursor: initial;
  &:hover {
    transform: translate(0);
    color: white;
    border: 1px solid rgba(230, 255, 247, 0.4);
  }
}

.button-only {
  margin: 8px 0;
}


.logo {
  $s: 64px;
  position: fixed;
  top: 16px;
  left: 16px;
  height: $s; 
  width: $s;
  img {
    height: $s;
    width: $s;
  }
}

.nav-btns {
  button + button {
    margin-left: 16px;
  }
}

.checker {
  input {
    background: none;
    border: none;
    border-bottom: 2px solid theme.$theme;
    margin-right: 16px;
    font-size: 24px;
    color: white;
    text-align: center;
    line-height: 36px;
  }
  input:focus {
    outline: none;
    border-bottom: 2px solid theme.$themeComplement;
  }


}

.dome-header {
  margin-top: 32px;
  border-top: 1px dashed theme.$themeTetrad;
}

.step-active--false {
  display: none;
}

.step {
  input {
    background: none;
    border: none;
    border-bottom: 2px solid theme.$theme;
    margin-right: 16px;
    font-size: 24px;
    color: white;
    text-align: center;
    line-height: 36px;
  }
  input:focus {
    outline: none;
    border-bottom: 2px solid theme.$themeComplement;
  }
  p {
    font-size: 20px;
  }
}

.galaxy {

}

.App__Nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  a {
    width: 128px;
    height: 48px;
    line-height: 48px;
    display: inline-block;
    border-top: 1px solid theme.$theme;
    margin: 0 8px;
  }
}

.App-wrapper {
  margin-bottom: 64px;
}

.orbit-visuals {
  $s: 480px;
  height: $s;
  width: $s;
  position: relative;
  margin: 0 auto 64px;
  .orbit {
    border-radius: 480px;
    border: 1px solid white;
    position: absolute;
    &--0 {
      $s_: $s/10;
      width: $s_;
      height: $s_;
      top: $s/2 - $s_/2;
      left: $s/2 - $s_/2; 
    }
    &--1 {
      $s_: $s/7;
      width: $s_;
      height: $s_;
      top: $s/2 - $s_/2;
      left: $s/2 - $s_/2; 
    }
    &--2 {
      $s_: $s/5;
      width: $s_;
      height: $s_;
      top: $s/2 - $s_/2;
      left: $s/2 - $s_/2; 
    }
    &--3 {
      $s_: $s/4;
      width: $s_;
      height: $s_;
      top: $s/2 - $s_/2;
      left: $s/2 - $s_/2; 
    }
    &--4 {
      $s_: $s/3;
      width: $s_;
      height: $s_;
      top: $s/2 - $s_/2;
      left: $s/2 - $s_/2; 
    }
    &--5 {
      $s_: $s/2.5;
      width: $s_;
      height: $s_;
      top: $s/2 - $s_/2;
      left: $s/2 - $s_/2; 
    }
    &--6 {
      $s_: $s/2;
      width: $s_;
      height: $s_;
      top: $s/2 - $s_/2;
      left: $s/2 - $s_/2; 
    }
    &--7 {
      $s_: $s/1.5;
      width: $s_;
      height: $s_;
      top: $s/2 - $s_/2;
      left: $s/2 - $s_/2; 
    }
    &--8 {
      $s_: $s;
      width: $s_;
      height: $s_;
      top: $s/2 - $s_/2;
      left: $s/2 - $s_/2; 
    }


  }

  .planet {
    position: absolute;
    top: 32px;
    left: 32px;
    right: 32px;
    bottom: 32px;
    border-radius: $s;
    background: white;
    &--Gas {
      @include theme.sgrad(theme.$themeComplement);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    &--Desert {
      @include theme.sgrad(theme.$themeTriadRight);
    }
    &--Oceanic {
       @include theme.sgrad(theme.$theme);
    }
    &--Earthlike {
       @include theme.sgrad(theme.$themeTetradAnti);
    }
    &--Greenhouse {
       @include theme.sgrad(theme.$themeTetrad);
    }
    &--Volcanic {
       @include theme.sgrad(red);
    }
    &--Paradise {
       @include theme.dgrad(theme.$themeTetrad, theme.$theme);
    }
    &--Rocky {
       @include theme.sgrad(brown);
    }
    &--Arctic {
       @include theme.sgrad(#eaeaea);
    }
    &--Mountainous {
       @include theme.sgrad(#2e2e2e);
    }
    &--Tiny {
      @include theme.sgrad(#2e2e2e);
      top: 64px;
      left: 64px;
      right: 64px;
      bottom: 64px;
    }

  }
}

@media only screen and (max-width: 640px) {
  .Lander__Focus h1 {
    font-size: 100px;
  }

  h2 {
    font-size: 48px;
    margin-top:64px;
  }

  .alchemist {
    max-width: 420px;
  }
  .App__Nav {
    a {
  
      width: 100px;
    }

  }
}


@media only screen and (max-width: 500px) {
  .Lander__Focus h1 {
    font-size: 80px;
  }
  h2 {
    font-size: 32px;

  }
  .alchemist {
    max-width: 340px;
  }
  .checker input {
    margin-right: 0;
    width: 100%;
    box-sizing: border-box;
  }
  .checker button {
    margin-top: 8px;
  }
  .mmbtn {
    top: 30px;
  }
  .App__Nav {
    a {
      font-size: 14px;
      width: 80px;
    }
    left: 0;
  }
}



@media only screen and (max-width: 420px) {

  .Lander h1 {
    margin-top: 64px;
  }
  .Lander__Focus h1 {
    font-size: 64px;
    margin-top: 80px;
  }
  .alchemist {
    max-width: 100%;
  }
  .dome-header div {
    font-size: 10px;
  }
  
  .App__Nav {
    a {
      font-size: 12px;
   
    }

  }
}